.ButterflyGraph {
  --square-size: 14px;
  $bp-mobile: 750px;
  $column-size: 39%;
  // layout size 671px
  // layout percentage 0.6034172662
  $selector: &;
  $border-radius: 2px;

  &-wrapper {
    display: flex;
    flex-direction: column;
    @include fs-min-width($bp-mobile) {
      width: 100%;
      // display: flex;
    }
  }

  &-list {
    @include fs-min-width($bp-mobile) {
      flex-basis: 60%;
      flex-grow: 1;
    }
  }

  &-header {
    margin-bottom: fs-rem(18px);

    @include fs-max-width($bp-mobile - 1px) {
      display: none;
    }

    @include fs-min-width($bp-mobile) {
      display: flex;
      justify-content: space-between;
    }
  }

  &-title {
    @include fs-scale(font-size, (600px: 12px, $layout-max-width: 16px));
    color: $bg-green-blue;
    font-weight: bold;
    letter-spacing: 1px;
    line-height: 1;
    text-transform: uppercase;

    &.is-mobile {
      @include fs-min-width($bp-mobile) {
        display: none;
      }
    }

    @include fs-max-width($bp-mobile - 1px) {
      margin-bottom: fs-rem(10px);
      margin-top: fs-rem(20px);
    }

    @include fs-min-width($bp-mobile) {
      flex-basis: $column-size;
      text-align: center;
    }
  }

  &-row {
    align-items: center;
    justify-content: space-between;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    // @include fs-min-width($bp-mobile) {
    //   display: flex;
    //   align-items: center;
    //   justify-content: space-between;
    // }

    @include fs-max-width($bp-mobile - 1px) {
      flex-wrap: wrap;
    }

    & + & {
      margin-top: fs-rem(30px);
    }
  }

  &-label {
    @include graph-label;
    flex-basis: 22%;
    flex-shrink: 1;
    text-align: center;

    @include fs-max-width($bp-mobile) {
      flex-basis: 100%;
      margin-bottom: fs-rem(6px);
    }
  }

  &-container {
    display: flex;
    flex-grow: 1;

    @include fs-max-width($bp-mobile - 1px) {
      // & + & {
        flex-basis: 100%;
      // }

      & + & {
        margin-top: fs-rem(10px);
      }
    }

    &.is-left {
      // Moves the bars that should be on the left side to the left in desktop view
      @include fs-min-width($bp-mobile) {
        order: -1;
        flex-direction: row-reverse;
      }
    }
  }

  &-sum {
    font-size: fs-rem(14px);
    align-self: center;
    font-weight: bold;

    @include fs-max-width($bp-mobile - 1px) {
      order: 1;
      margin-left: fs-rem(7px);
    }

    @include fs-min-width($bp-mobile) {
      &.is-right {
        order: 1;
        margin-left: fs-rem(14px);
      }

      &.is-left {
        order: 1;
        margin-right: fs-rem(14px);
      }
    }
  }

  &-bar {
    align-items: center;
    background-color: var(--color);
    display: flex;
    height: fs-rem(20px);
    justify-content: center;
    width: calc(var(--size) * 2); // scaling everything by two to make it more readable

    @include fs-min-width($bp-mobile) {
      height: var(--bar-height-desktop, 35px);
    }

    @include fs-max-width($bp-mobile - 1px) {
      height: var(--bar-height-mobile, 25px);
      // width: calc(var(--size) * 3); // scaling everything by two to make it more readable

    }

    // When the bar is on the left side we are changing the order of bar and its text value
    &.is-left {
      flex-direction: row-reverse;

      @include fs-min-width($bp-mobile) {
        &:first-child {
          margin-left: auto;
        }
      }

      > #{$selector}-bar-value {
        order: 1;
      }

      > #{$selector}-var-inner {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }

    // When the bar is on the right side we are changing the order of bar and its text value
    &.is-right {
      > #{$selector}-bar-value {
        order: 1;
      }

      > #{$selector}-var-inner {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }

    &-value {
      color: #000;
      font-size: fs-rem(10px);
      font-weight: $work-sans-bold;
      letter-spacing: 1px;
      line-height: 1.6;
      margin-left: fs-rem(3px);
      margin-right: fs-rem(3px);
    }

    // &-inner {
    //   background-color: var(--color);
    //   height: fs-rem(20px);
    //   width: var(--size);

    //   @include fs-min-width($bp-mobile) {
    //     height: var(--bar-height-desktop, 35px);
    //   }

    //   @include fs-max-width($bp-mobile - 1px) {
    //     height: var(--bar-height-mobile, 25px);
    //   }
    // }
  }

  &-legend {
    @include fs-max-width($bp-mobile - 1px) {
      margin-bottom: fs-rem(15px);
    }

    @include fs-min-width($bp-mobile) {
      order: 1;
      columns: 2;
      gap: 22%;
      margin-top: fs-rem(31px);
    }

    &-item {
      display: flex;

      & + & {
        margin-top: fs-rem(12px);
      }
    }

    &-square {
      background-color: var(--legend-color);
      border-radius: $border-radius;
      flex-shrink: 0;
      height: var(--square-size, #{fs-rem(14px)});
      margin-right: fs-rem(9px);
      width: var(--square-size, #{fs-rem(14px)});
    }

    &-label {
      @include graph-label
    }
  }

  &-download {
    margin-top: fs-rem(50px);

    &-link {
      @include link-download;
      display: inline-flex;
      align-items: center;
      text-decoration: underline;
      text-decoration-color: $c-blue;

      &-text {
        border-bottom: 2px solid $c-blue;
      }
    }
  }
}
