// -------------------------------------------------------------------------
// Color variables
//
// Use variable names that describe the color. Avoid additional abstraction
// unless absolutely necessary (e.g. themeing)
// http://bensmithett.com/stop-using-so-many-sass-variables/
//
// Can’t think of another name for a similar color? Try this site:
// http://chir.ag/projects/name-that-color/
//
// Note: We’re using the “postcss-custom-properties” plugin to add
//       fallbacks for browsers that don’t support CSS vars.
//       https://caniuse.com/css-variables
// -------------------------------------------------------------------------


// Background colors
// -------------------------------------
$bg-blue-grey: #bfcfd4;
$bg-skyblue: #90e1f8;
$bg-blue: #00a6d6;
$bg-blue-light: #32b8df;
$bg-blue-med: #11596d;
$bg-green-blue: #003240;
$bg-orange: #f16623;
$bg-green: #aad533;

// Text colors
// -------------------------------------
$c-blue-grey: #bfcfd4;
$c-skyblue: #90e1f8;
$c-blue: #00a6d6;
$c-blue-light: #32b8df;
$c-blue-med: #11596d;
$c-green-blue: #003240;
$c-orange: #f16623;
$c-green: #aad533;
$c-red: #f12353;

// Default colors
// FIXME: Update these colors
//----------------------------
$bg-default: #fff;
$c-default: #000;
