// -------------------------------------------------------------------------
// Font variables
//
// Note: Fallback to system sans-serif fonts since they support multiple weights.
// All modern operating systems support the following weights:
// - 900/800/700 Heavy
// - 600 Bold
// - 500/400 Regular
// - 300/200/100 Light
// (OS X and iOS support more)
// Demo http://output.jsbin.com/morukug/quiet
// Cross-browser results https://workflowy.com/s/BHZf.wXqmEGAjs4
//
// sass-lint:disable indentation, no-color-keywords
// -------------------------------------------------------------------------
// Base font stacks
// Note: Quotes arn’t necessary for most font names
//       See https://mothereff.in/font-family
// Note: “color-emoji” is a custom font defined in _fonts.scss
//       See https://www.client9.com/css-color-emoji-stack/ for details.
$sans-serif: -apple-system, Segoe UI, sans-serif, color-emoji;
$serif: Times New Roman, Times, serif, color-emoji;
$monospace: Operator Mono, Consolas, Menlo, Monaco, monospace, color-emoji;

// Custom web fonts
//---------------------------------
// Note: If using Helvetica or Helvetica Neue, you can try these font stacks
// to try to avoid having to load the web font:
// https://gist.github.com/tedw/69576a543fbfe6cedb3d4c3fa0284eab

$work-sans: "Work Sans", $sans-serif;// default font stack
$bodoni-mioda: "Bodoni Moda", $serif;

$work-sans-semibold: 500;
$work-sans-bold: 600;

// Body copy vars
//---------------------------------
// FIXME: Define default font styles
$body-copy-font: $work-sans;
$body-copy-min-size: 17px;
$body-copy-max-size: 18px;
$body-copy-line-height: 1.5;
