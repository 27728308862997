.Header {
  --icon-color: #{$c-orange};

  $chevron-icon-size: 26px;
  $download-icon-size: 24px;

  left: 50%;
  margin-left: auto;
  margin-right: auto;
  max-width: fs-rem($layout-max-width);
  position: absolute;
  top: fs-rem(32px);
  transform: translateX(-50%);
  width: 100%;
  z-index: fs-zindex('progressIndicator');

  @include fs-max-width($layout-nav-bp - 1px) {
    display: none;
  }

  &-wrapper {
    background-color: $bg-green-blue;
    border-radius: 43px;
    padding: fs-rem(25px 40px);
  }

  &-list {
    display: flex;
    align-items: center;

    &-item {
      &:first-child {
        margin-right: auto;
      }

      &:last-child {
        margin-left: fs-rem(60px);
      }
    }
  }

  &-link {
    color: #fff;
    font-family: $work-sans;
    font-size: fs-rem(14px);
    letter-spacing: 2px;
    line-height: 1.14;
    text-transform: uppercase;
    display: flex;
    align-items: center;

    .chevron-icon {
      border-radius: 100%;
      border: 1px solid $c-orange;
      height: fs-rem($chevron-icon-size);
      margin-right: fs-rem(14px);
      width: fs-rem($chevron-icon-size);
    }

    .download-icon {
      height: fs-rem($download-icon-size);
      margin-left: fs-rem(6px);
      width: fs-rem($download-icon-size);
    }
  }

  &-downloadLink {
    border-bottom: 2px solid $c-orange;
  }
}
