.TextExpansion {
  --plus-minus-size: 32px;
  $bp-mobile: 1100px;

  &-text {
    @include fs-scale(font-size, (375px: 18px, $layout-max-width: 20px));
    color: #fff;
    line-height: 1.3;

    &.is-desktop {
      @include fs-max-width($bp-mobile - 1px) {
        display: none;
      }
    }
  }

  &-toggle {
    @include button-reset;
    align-items: center;
    color: #fff;
    display: flex;
    font-size: fs-rem(16px);
    font-weight: $work-sans-bold;
    letter-spacing: 2px;
    line-height: 1;
    text-transform: uppercase;

    @include fs-min-width($bp-mobile) {
      display: none;
    }

    &-plusMinus {
      @include plusMinusIcon($width: 16px, $color: $c-blue);
      border-radius: 100%;
      border: 2px solid $c-blue;
      display: block;
      height: var(--plus-minus-size);
      margin-left: fs-rem(13px);
      width: var(--plus-minus-size);
    }
  }


  &-content {
    @include expandable-height;
    margin-top: fs-rem(10px);
  }
}
