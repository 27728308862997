.ProgressIndicator {
  --menu-circle-color: #fff;
  --menu-circle-size: #{fs-rem(20px)};
  --menu-size: #{fs-rem(82px)};
  --text-width: 0;
  --text-height: 0;
  --text-margin-right: 0;

  $menu-size: 310px;

  position: fixed;
  background-color: #000;
  border-radius: 43px;
  opacity: 0.75;
  width: var(--menu-size); // magic number
  right: fs-rem(16px);
  top: 50%;
  transform: translateY(-50%);
  transition: 0.5s width;
  z-index: fs-zindex('progressIndicator');

  @include fs-max-width($layout-nav-bp - 1px) {
    display: none;
  }

  &:focus-within,
  &:hover,
  &.is-open {
    --menu-size: #{fs-rem($menu-size)}; // magic number
    --text-width: auto;
    --text-margin-right: #{fs-rem(12px)};
    --text-height: auto;
  }

  &-list {
    padding: fs-rem(30px);
    &-item {
      & + & {
        margin-top: fs-rem(12px);
      }
    }
  }

  &-sublist {
    &-item {
      margin-top: fs-rem(10px);
    }
  }

  &.is-active {
    --menu-circle-color: #{$c-orange};
    --menu-size: auto;
  }

  &.has-past {
    --menu-circle-color: #fff;
  }

  &-link {
    align-items: center;
    color: #fff;
    display: flex;
    font-family: $work-sans;
    font-size: fs-rem(12px);
    font-weight: var(--link-weight);
    justify-content: flex-end;
    letter-spacing: fs-rem(2px);
    overflow: hidden; // hides text
    text-transform: uppercase;

    &-text {
      flex-shrink: 0;
      margin-right: var(--text-margin-right);
      overflow: hidden;
      transition: 0.5s width;
      width: var(--text-width);
      height: var(--text-height);
      overflow: hidden;
    }

    &::after {
      background-color: var(--menu-circle-color);
      border-radius: 100%;
      content: '';
      display: inline-block;
      height: var(--menu-circle-size);
      margin-left: auto;
      width: var(--menu-circle-size);
      flex-shrink: 0;
    }

    &.is-child {
      &::after {
        background-color: transparent;
        width: var(--menu-circle-size);
        height: var(--menu-circle-size);
        border: 3px solid var(--menu-circle-color);
      }
    }

    @include fs-attention {
      --link-weight: bold;
      --menu-circle-color: #{$c-orange};
    }

    &.is-active {
      --link-weight: bold;
      --menu-circle-color: #{$c-orange};
    }
  }
}
