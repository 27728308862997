// -------------------------------------------------------------------------
// Button Styles
// -------------------------------------------------------------------------
@mixin button-reset {
  background-color: transparent;
  background-image: none; // for Firefox mobile
  border: 0;
  color: inherit;
  cursor: pointer;
  display: inline-block;
  line-height: 1;
  text-align: center;
  transition: background-color 150ms ease-in-out,
              background-position 150ms ease,
              border 150ms ease-in-out,
              color 150ms ease-in-out;
}

@mixin button-base {
  @include button-reset;
  border-radius: $button-border-radius;
  font-size: fs-rem(18px);
  font-weight: bold;
  padding: fs-rem($button-padding-vert $button-padding-horz);
}

// FIXME: Update button style to match design
@mixin button-solid($color: #fff, $bg-color: $c-green-blue) {
  @include fs-font-smoothing;
  background-color: var(--button-bg-color, $bg-color);
  border-radius: 56px;
  border: 2px solid transparent;
  color: var(--button-color, $color);
  padding: fs-rem(7px 10px);

  @include button-attention {
    background-color: var(--button-color, $color);
    border-color: currentColor;
    color: var(--button-bg-color, $bg-color);
    text-decoration: none;// for link tags
  }
}

// FIXME: Update button style to match design
@mixin button-border($color: $c-green-blue, $hover-color: #fff) {
  background-color: transparent;
  border-radius: 56px;
  border: 2px solid var(--button-color, $color);
  color: var(--button-color, $color);
  display: inline-block;
  font-weight: $work-sans-bold;
  letter-spacing: 2px;
  padding: fs-rem(7px 10px);
  text-transform: uppercase;

  @include button-attention {
    @include fs-font-smoothing;
    background-color: var(--button-color, $color);
    color: var(--button-hover-color, $hover-color);
    text-decoration: none;// for link tags
  }
}

// Example styles to append an icon based on the URL
// @mixin button-icon($bg-color, $color) {
//   $icon-width: 15px;
//   $icon-margin-left: 10px;
//   $icon-margin-right: 15px;
//   background-image: fs-svg-url('arrowRight', (fill: $color));
//   background-repeat: no-repeat;
//   background-position: right $icon-margin-right center;
//   background-size: $icon-width auto;
//   padding-right: fs-rem($icon-margin-left + $icon-width + $icon-margin-right);
//
//   @include button-attention {
//     background-image: fs-svg-url('arrowRight', (fill: $bg-color));
//   }
//
//   // Update icon for external links
//   &[href^="http"]:not([href*="lndo.site"]):not([href*="//localhost"]):not([href*="pantheonsite.io"]):not([href*="fixme.org"]) {
//     $icon-width: 13px;
//     background-image: fs-svg-url('external', (fill: $color));
//     background-size: $icon-width auto;
//     padding-right: fs-rem($icon-margin-left + $icon-width + $icon-margin-right);
//
//     @include button-attention {
//       background-image: fs-svg-url('external', (fill: $bg-color));
//     }
//   }
//
//   // Use download icon for internal links to files in media library
//   &[href^="/"][href*="/wp-content/uploads/"] {
//     $icon-width: 12px;
//     background-image: fs-svg-url('download', (fill: $color));
//     background-size: $icon-width auto;
//     padding-right: fs-rem($icon-margin-left + $icon-width + $icon-margin-right);
//
//     @include button-attention {
//       background-image: fs-svg-url('download', (fill: $bg-color));
//     }
//   }
// }
