.Banner {
  position: relative;
  // overflow: hidden; // cuts off the banner if it exceeds the height of the viewport

  @include fs-min-width($layout-banner-bp) {
    // aspect-ratio: 1440 / 1117;
    min-height: 100vh; // ensures that the banner doesn't exceed viewport
  }

  &-img {
    @include fs-absolute-fill;
    z-index: -1;

    &-src {
      height: 100%;

      img {
        height: 100%;
        object-fit: cover;
        width: 100%;
      }
    }
  }

  &-overlay {
    @include fs-scale(padding-top, (600px: 119px, $layout-max-width: 344px));
    background-color: rgba(#000, .7);
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    justify-content: center;
    padding-bottom: fs-rem(30px);

    @include fs-min-width($layout-banner-bp) {
      width: 70%;
    }
  }

  &-content {
    margin-left: auto;
    margin-right: auto;
    width: 74%;
  }

  &-title {
    @include fs-scale(font-size, (375px: 37px, $layout-max-width: 100px));
    @include fs-scale(margin-bottom, (375px: 14px, $layout-max-width: 38px));
    color: #fff;
    font-weight: $work-sans-semibold;
    line-height: 1;

    &--italic {
      @include fs-scale(font-size, (375px: 42px, $layout-max-width: 115px));
      font-family: $bodoni-mioda;
      font-style: italic;
    }
  }

  &-next {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;

    @include fs-max-width($layout-banner-bp - 1px) {
      width: 100%;
    }

    &-text {
      @include fs-scale(font-size, (375px: 12px, $layout-max-width: 14px));
      color: #fff;
      font-weight: $work-sans-bold;
      letter-spacing: 2px;
      margin-bottom: fs-rem(15px);
      text-transform: uppercase;
      z-index: 2;

      @include fs-max-width($layout-banner-bp - 1px) {
        text-align: center;
      }
    }

    .NextPrev-btn--next {
      position: static;

      @include fs-max-width($layout-banner-bp - 1px) {
        transform: none;
      }
    }
  }

  &-text {
    @include fs-scale(font-size, (375px: 16px, $layout-max-width: 20px));
    color: #fff;
    line-height: 1.3;

    &+& {
      @include fs-min-width($layout-banner-bp) {
        margin-top: fs-rem(30px);
      }
    }

    // Any text expansion class will have gutter space between it and the text
    &+.TextExpansion {
      margin-top: fs-rem(30px);
    }
  }

  &-row {
    align-items: center;
    display: flex;
    width: 100%;

    @include fs-min-width($layout-banner-bp - 1px) {
      margin-bottom: fs-rem(45px);
      margin-top: fs-rem(90px);
    }

    @include fs-max-width($layout-banner-bp - 1px) {
      margin-top: fs-rem(84px);
    }
  }

  &-logo {
    display: inline-block;
    margin-right: auto;
    width: fs-rem(100px);

    @include fs-max-width($layout-banner-bp - 1px) {
      display: none;
    }

    svg {
      height: auto;
      width: 100%;
    }
  }
}
