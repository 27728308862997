//------------------------------------------------------------------------
// Expand/collapse styles
// For use with https://github.com/Threespot/expand-toggle
//------------------------------------------------------------------------

// Default mixin, slides content down using “translateY” but won’t change the height
@mixin expandable($speed: 500ms, $easing: fs-easing("easeOutCubic")) {
  // Collapsed
  transform: translateY(-100%);
  visibility: hidden;

  &[aria-hidden="true"] {
    transition: transform $speed $easing, visibility 0s linear $speed;
  }

  // Expanded
  &[aria-hidden="false"],
  .no-js & {
    transform: translateY(0);
    transition: transform $speed $easing, visibility 0s linear 0s;
    visibility: visible;
  }
}

@mixin undo-expandable {
  transform: none !important;
  overflow: visible !important;
  visibility: visible !important;
}

// Use this mixin when applying the “data-expands-height” attribute
@mixin expandable-height($speed: 500ms, $easing: fs-easing("easeOutCubic")) {
  // Collapsed
  max-height: 0;
  overflow: hidden;
  pointer-events: none;
  visibility: hidden;

  &[aria-hidden="true"] {
    transition: max-height $speed $easing, visibility 0s linear $speed;
  }

  // Expanded
  &[aria-hidden="false"],
  .no-js & {
    max-height: none; // prevent FOUC when menu defaults to expanded on load
    pointer-events: auto;
    transition: max-height $speed $easing, visibility 0s linear 0s;
    visibility: visible;
  }

  // 1. “!important” required to override inline styles added by JS
  &[aria-hidden="true"][style] {
    max-height: 0 !important; // [1]
  }
}

@mixin undo-expandable-height {
  max-height: none !important;
  overflow: visible !important;
  visibility: visible !important;
}
