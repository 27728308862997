.SlideStyleA {
  $bp-small: 900px;

  @include fs-max-width($bp-small - 1px) {
    padding-top: fs-rem(50px);
  }

  @include fs-min-width($bp-small) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: fs-rem(140px);
    padding-top: fs-rem(140px);
    width: 100%;
  }

  &-slideTitle {
    padding-bottom: 10px;
    border-bottom: $bg-blue 3px solid;
    margin-bottom: 24px;
  }

  &-wrap {
    @include fs-min-width($bp-small) {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  &-secondary {
    max-width: 540px;
    flex-shrink: 1;

    &-heading {
      margin-bottom: 16px;

      @include fs-min-width($bp-small) {
        margin-bottom: 30px;
      }
    }
  }
}
