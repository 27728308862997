// -------------------------------------------------------------------------
// Link Styles
// -------------------------------------------------------------------------
// Base link styles, excluding color
@mixin link-base {
  transition: color 150ms ease-in-out;

  @include fs-all-states {
    text-decoration: none;
  }

  // Optional: Only uncomment if adding dark-mode support to entire site
  // @media (prefers-color-scheme: dark) {
  //   @include fs-all-states {
  //     color: currentColor !important;
  //   }
  // }
}

// FIXME: Name mixin based on style
// @mixin link-fixme($color: mediumvioletred) {
//   @include fs-all-states {
//     // Optional: Use CSS var to make it easier to override colors
//     color: var(--link-color, $color);
//     text-decoration: underline;

//     // Inherit custom block text color
//     .has-text-color & {
//       color: currentColor;
//     }
//   }

//   // Optional: Customize the text-decoration style
//   // @supports (text-decoration-thickness: 1px) {
//   //   text-decoration-color: currentColor;
//   //   text-decoration-line: underline;
//   //   text-decoration-thickness: 2px;
//   //   text-underline-offset: 4px;
//   // }

//   @include fs-attention {
//     text-decoration: none;
//   }
// }

@mixin link-icon($color: $c-green-blue) {
  align-items: center;
  color: var(--link-icon-color, #{$color});
  display: flex;
  font-size: var(--link-font-size, #{fs-rem(18px)});
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: $work-sans-bold;

  @include fs-attention {
    text-decoration: underline;
    text-decoration-color: $c-blue;
    color: var(--link-icon-hover-color, #{$c-blue});

    .icon-chevron {
      transform: rotateY(180deg) translateX(-3px);
    }
  }

  .icon {
    --icon-size: 32px;
    --icon-color: #{$c-blue};
    height: var(--icon-size, #{fs-rem(32px)});
    margin-left: fs-rem(13px);
    width: var(--icon-size, #{fs-rem(32px)});
    flex-shrink: 0;
  }

  .icon-chevron {
    transform: rotateY(180deg);
    transition: transform 500ms;
  }
}

// Custom named link styles
//---------------------------------
@mixin link-richtext() {
  @include link-base;

  @include fs-all-states {
    text-decoration: underline;
  }
  // @include link-fixme;
}


@mixin link-download($color: $c-green-blue) {
  color: var(--link-color, #{$c-green-blue});
  font-size: var(--link-font-size, #{fs-rem(14px)});
  font-weight: $work-sans-bold;
  letter-spacing: 2px;
  text-decoration-color: var(--link-underline-color, #{$c-blue});
  text-decoration: underline;
  text-transform: uppercase;

  .icon {
    --icon-size: 24px;
    --icon-color: #{$c-blue};
    height: var(--icon-size);
    width: var(--icon-size);
    margin-left: fs-rem(10px);
  }
}
