//------------------------------------------------------------------------
// Rich text heading styles
//
// Note: Be aware that “text-rendering: optimizeLegibility” can sometimes
//       result in edge-case issues. Make sure to test if using it.
//------------------------------------------------------------------------
@mixin h2($color: $c-default) {
  @include fs-scale(font-size, (360px: 34px, $layout-text-scale-end-bp: 48px));
  color: $color;
  font-family: $serif;
  font-weight: bold;
  line-height: 1.25;
}

@mixin h3($color: #000) {
  @include fs-scale(font-size, (360px: 20px, $layout-text-scale-end-bp: 22px));
  color: $color;
  font-family: $work-sans;
  font-weight: $work-sans-bold;
  line-height: 1.1;
}

@mixin h4($color: $c-default) {
  @include fs-scale(font-size, (360px: 26px, $layout-text-scale-end-bp: 32px));
  color: $color;
  font-family: $serif;
  font-weight: normal;
  line-height: 1.4;
}

@mixin h5($color: $c-default) {
  @include fs-scale(font-size, (360px: 22px, $layout-text-scale-end-bp: 26px));
  color: $color;
  font-family: $sans-serif;
  font-weight: bold;
  line-height: 1.4;
}

@mixin h6($color: $c-default) {
  @include fs-scale(font-size, (360px: 16px, $layout-text-scale-end-bp: 22px));
  color: $color;
  font-family: $sans-serif;
  font-weight: bold;
  line-height: 1.4;
}
