.SlideStyleB {
  $bp-small: 900px;

  @include fs-max-width($bp-small - 1px) {
    padding-top: fs-rem(50px);
  }

  @include fs-min-width($bp-small) {
    padding-bottom: fs-rem(140px);
    padding-top: fs-rem(140px);
    display: flex;
    align-items: center;
  }

  &-primary {
    @include fs-min-width($bp-small) {
      display: flex;
      align-items: flex-start;
    }
  }

  &-slideTitle {
    padding-bottom: 10px;
    border-bottom: $bg-blue 3px solid;
    margin-bottom: 24px;

    @include fs-min-width($bp-small) {
      margin-right: 134px;
    }
  }

  &-secondary {
    max-width: 540px;
    flex-shrink: 1;

    &-heading {
      @include h3;
      // margin-bottom: 16px;

      @include fs-min-width($bp-small) {
        // margin-bottom: 30px;
      }
    }

    &-description {
      a {
        color: $c-blue-med;
        text-decoration: underline;

        &:visited,
        &:link {
          text-decoration: underline;
        }
      }
    }
  }

  &-additional {
    margin-top: fs-rem(50px);
  }
}
