//------------------------------------------------------------------------
// Margin helpers in multiples of 1em
//------------------------------------------------------------------------
@for $i from 0 through 10 {
  $val: $i * 10px;
  .mt-#{$i} { margin-top: fs-rem($val) !important; }
  .mb-#{$i} { margin-bottom: fs-rem($val) !important; }
  // .ml-#{$i} { margin-left: $val !important; }
  // .mr-#{$i} { margin-right: $val !important; }
}
