//------------------------------------------------------------------------
// Styles for custom headings and labels used throughout the project.
//
// Each of these should also be turned into a helper class in _font-helpers.scss
//
// For rich text heading styles (e.g. h2–h6), see _rich-text-headings.scss
//------------------------------------------------------------------------
@mixin text-size-scale($min: $body-copy-min-size, $max: $body-copy-max-size) {
  @include fs-scale(font-size, (375px: $min, $layout-text-scale-end-bp: $max));
}


@mixin graph-label() {
  font-size: fs-rem(12px);
  font-weight: $work-sans-bold;
  line-height: 1.33;
  text-transform: uppercase;
}
