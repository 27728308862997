//------------------------------------------------------------------------
// Universal helper classes
//
// Add !important to all properties to force immutability
// http://csswizardry.com/2016/05/the-importance-of-important/
//
// sass-lint:disable no-important
// scss-lint:disable ImportantRule
//------------------------------------------------------------------------
.u {

  &-richtext { @include richtext; }

  &-hide { display: none !important; }

  // Hide text visually but keep it accessible to screen readers
  &-screenreader { @include fs-visually-hidden; }

  &-clearfix { @include fs-clearfix; }

  &-clear { clear: both; }

  // Prevent text from wrapping
  &-nowrap {
    @include fs-min-width(320px) {
      display: inline-block;// necessary for nested inline elements for some reason
      text-decoration: inherit;// don’t break link styles
      white-space: nowrap !important;

      @supports (text-decoration-color: #000) {
        text-decoration-color: inherit;// Safari fix
      }
    }
  }

}// end u
