.BarChart {
  $bp-2-col: 700px;
  $border-radius: 2px;

  &-row {
    display: flex;

    @include fs-max-width($bp-2-col - 1px) {
      flex-direction: column;
    }

    @include fs-min-width($bp-2-col) {
      align-items: center;
      justify-content: space-between;
    }
  }

  &-container {
    @include fs-scale(height, ($bp-2-col: 650px, $layout-max-width: 350px));
    // @include fs-scale(height, ());
    width: 100%;
    position: relative;
  }

  &-download {
    margin-top: fs-rem(30px);
    &-link {
      @include link-download;
      display: inline-flex;
      align-items: center;

      &-text {
        border-bottom: 2px solid $c-blue;
      }
    }
  }

  &-legend {
    margin-bottom: fs-rem(14px);

    @include fs-max-width($bp-2-col - 1px) {
      order: -1;
    }

    @include fs-min-width($bp-2-col) {
      margin-left: fs-rem(20px);
    }

    &-text {
      margin-bottom: fs-rem(14px);
      font-size: fs-rem(14px);
    }

    &-item {
      cursor: pointer;
      display: flex;

      & + & {
        margin-top: fs-rem(8px);
      }
    }

    &-square {
      align-items: center;
      border-radius: $border-radius;
      display: inline-flex;
      flex-shrink: 0;
      height: var(--square-size, #{fs-rem(14px)});
      margin-right: fs-rem(9px);
      transition: background-color 500ms;
      width: var(--square-size, #{fs-rem(14px)});

      .icon {
        height: auto;
        width: 100%;
      }
    }

    &-label {
      font-size: fs-rem(12px);
      font-weight: bold;
      letter-spacing: 1px;
      line-height: 1.33;
      text-transform: uppercase;
    }
  }
}
