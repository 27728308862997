.Section {
  width: 100vw;
  background-color: #fff;

  &.yellow {
    background-color: yellow;
  }

  &.black {
    background-color: black;
  }

  &.green {
    background-color: green;
  }

  &.orange {
    background-color: orange;
  }

  &.pink {
    background-color: pink;
  }
}
