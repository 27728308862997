.ShareCards {
  $bp-3-col: 900px;

  --card-bg-color: #fff;
  --card-text-color: #000;
  --card-link-color: #{$c-green-blue};
  --card-stat-border-color: #{$c-blue};
  --card-stat-border-size: 144px;
  --icon-color: #fff;
  --icon-bg-color: #{$c-blue};
  --icon-size: 38px;

  margin-top: calc((var(--card-stat-border-size) / 2) + 50px);
  margin-bottom: 64px;

  @include fs-min-width($bp-3-col) {
    display: flex;
    justify-content: center;
    align-items: stretch;
    margin-left: fs-rem(-37px);
  }


  &-item {
    flex-basis: calc(33% - 37px);
    margin-left: fs-rem(37px);

    @include fs-max-width($bp-3-col - 1px) {
      margin-left: auto;
      margin-right: auto;
      max-width: fs-rem(550px);
    }

    &+& {
      @include fs-max-width($bp-3-col - 1px) {
        margin-top: calc((var(--card-stat-border-size) / 2) + 25px);
      }
    }
  }

  &-card {
    background-color: var(--card-bg-color);
    border-radius: 11px;
    border: 4px solid var(--card-stat-border-color);
    color: var(--card-text-color);
    height: 100%;
    padding-top: 0;
    padding: fs-rem(37px 25px);
    position: relative;
    transition: background-color 500ms, border-color 500ms;

    @include fs-min-width($bp-3-col) {
      display: flex;
      flex-direction: column;
    }

    &:has(a:hover),
    &:has(a:focus),
    &:hover {
      --card-bg-color: #{$bg-blue-med};
      --card-link-color: #fff;
      --card-stat-border-color: #fff;
      --card-text-color: #fff;
      --icon-bg-color: #fff;
      --icon-color: #{$c-blue-med};
    }

    &-header {
      align-items: center;
      background-color: var(--card-bg-color);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      left: 50%;
      position: absolute;
      transform: translate(-50%, -50%);
      transition: background-color 500ms, border-color 500ms;
      top: 0;
    }

    &-body {
      padding-top: calc(var(--card-stat-border-size) / 3);
    }

    &-footer {
      @include fs-min-width($bp-3-col) {
        margin-top: auto;
      }
    }

    &-stat {
      align-items: center;
      border-radius: 50%;
      border: 4px solid var(--card-stat-border-color);
      color: currentColor;
      display: flex;
      font-size: fs-rem(30px);
      font-weight: bold;
      height: var(--card-stat-border-size);
      justify-content: center;
      width: var(--card-stat-border-size);
    }

    &-text {
      color: currentColor;
      line-height: 1.38;
      margin-top: fs-rem(15px);
    }

    &-link {
      align-items: center;
      color: var(--card-link-color);
      display: flex;
      font-size: fs-rem(18px);
      font-weight: $work-sans-bold;
      letter-spacing: 2px;
      line-height: 1.13;
      margin-top: fs-rem(24px);
      text-transform: uppercase;
    }

    &-iconLink {
      align-items: center;
      background-color: var(--icon-bg-color);
      border-radius: 50%;
      display: block;
      display: flex;
      height: var(--icon-size);
      justify-content: center;
      transition: background-color 500ms, transform 500ms;
      width: var(--icon-size);
      padding: fs-rem(10px);

      .icon {
        fill: var(--icon-color);
        transition: fill 500ms;
        width: 100%;
      }

      @include fs-attention {
        // --icon-color: #{$c-blue};
        transform: scale(1.2);
      }
    }
  }


  &-shareLinks {
    display: flex;
    margin-top: fs-rem(15px);

    &-item {
      &+& {
        margin-left: fs-rem(10px);
      }
    }
  }

}
