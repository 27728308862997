//------------------------------------------------------------------------
// Add plus/minus icon to expandable toggles
//------------------------------------------------------------------------
// stylelint-disable no-descending-specificity

@mixin plusMinusIcon($width: 10px, $thickness: 2px, $position: 0, $color: currentColor) {
  position: relative;

  &::before,
  &::after {
    border-top: $thickness solid $color;
    content: "";
    position: absolute;
    right: $position;
    top: 50%;
    transform: translateX(-50%);
    width: fs-rem($width);
    border-radius: 3px;
    left: 50%;

    .no-js & {
      display: none;
    }
  }
  // Plus sign, i.e. |
  &::after {
    // Moving the pipe "|" sign half its width because we want it to be in the center of the negative sign
    // We want translateX to be evaluated after rotating so we have translateX before rotate property
    transform: translateX(-$width / 2) rotate(90deg);
    transition: transform 150ms ease;
  }

  // Minus sign, i.e. -
  [aria-expanded="true"] > & {
    &::after {
      transform: translateX(-$width / 2) rotate(0);
    }
  }
}
