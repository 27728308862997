//------------------------------------------------------------------------
// Font style helper classes
//
// sass-lint:disable no-important
// scss-lint:disable ImportantRule
//------------------------------------------------------------------------
.f {

  // Scale the body copy font-size relative to the viewport width
  // Note: This won’t affect h1–h6, which are set in rems (see _rich-text-headings.scss).
  &-scale {
    @include text-size-scale;
  }

  &-center {
    text-align: center;
  }

  &-bold {
    font-weight: $work-sans-bold;
  }

  &-slideTitle {
    font-size: fs-rem(38px);
    text-transform: uppercase;
    font-weight: $work-sans-bold;
    color: $c-green-blue;

    @include fs-min-width(900px) {
      font-size: fs-rem(40px);
    }
  }

  &-heading {
    font-weight: $work-sans-bold;
    font-size: 22px;
    line-height: 1.1;
  }

  // Makes numbers all the same height (requires OpenType support)
  // This is nice for phone numbers and addresses, as well as data tables.
  // &-lining-figures {
  //   font-feature-settings: "lnum", "onum" off;
  // }

  // Web fonts
  //---------------------------------
  // &-montserrat {
  //   font-family: $montserrat;
  // }

  // Rich-text headings
  //---------------------------------
  &-h2 {
    @include h2;
  }

  &-link-icon {
    @include link-icon;
  }

  &-chart-title {
    font-size: 25px;
    font-weight: $work-sans-bold;
    line-height: 1.5;
    letter-spacing: 2px;
    margin-bottom: 8px;
    text-transform: uppercase;
  }

  &-chart-subtitle {
    font-size: fs-rem(14px);
    margin-bottom: fs-rem(14px);

    .f-chart-title + & {
      margin-top: -8px; // remove the extra 8px from chart title margin-top
    }
  }



  &-data {
    font-size: 14px;
    font-weight: $work-sans-bold;
    letter-spacing: 2px;
    text-transform: uppercase;
  }

}

// end f
