//------------------------------------------------------------------------
// Web fonts
// Note: Only woff and woff2 are required for modern browsers
// https://css-tricks.com/snippets/css/using-font-face/#article-header-id-1
//
// In the future, we could consider not loading fonts if “data-saver” mode is enabled
// https://css-tricks.com/responsible-conditional-loading/
// https://caniuse.com/mdn-css_at-rules_media_prefers-reduced-data
//------------------------------------------------------------------------

// We’re using “font-display: fallback” on the public site as a compromise
// between performance and minimizing the chances of a FOIT or FOUC.
//
//   “The browser will hide the text for about 100ms and, if the font
//   has not yet been downloaded, will use the fallback text. It will
//   swap to the new font after it is downloaded, but only during a
//   short swap period (probably 3 seconds).”
//   https://css-tricks.com/almanac/properties/f/font-display/
//
// In the admin, the web font can take longer than 3 sec to load,
// so we’re switching to “font-display: swap” to allow an unlimited
// swap window.
$font-display: fallback;

// Custom emoji font to standardize appearance across platforms
// https://www.client9.com/css-color-emoji-stack/
// https://nolanlawson.com/2022/04/08/the-struggle-of-using-native-emoji-on-the-web/
@font-face {
  font-family: "color-emoji";
  src: local("Apple Color Emoji"),
       local("Twemoji Mozilla"),
       local("Segoe UI Emoji"),
       local("Segoe UI Symbol"),
       local("Noto Color Emoji"),
       local("EmojiOne Color"),
       local("Android Emoji");
}

@font-face {
  font-display: $font-display;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/work-sans-v18-latin-regular.woff") format("woff"),
       url("../fonts/work-sans-v18-latin-regular.woff2") format("woff2");
}

@font-face {
  font-display: $font-display;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/work-sans-v18-latin-500.woff") format("woff"),
       url("../fonts/work-sans-v18-latin-500.woff2") format("woff2");
}

@font-face {
  font-display: $font-display;
  font-family: "Work Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/work-sans-v18-latin-600.woff") format("woff"),
       url("../fonts/work-sans-v18-latin-600.woff2") format("woff2");
}

@font-face {
  font-display: $font-display;
  font-family: "Bodoni Moda";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/bodoni-moda.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;

}

@font-face {
  font-display: $font-display;
  font-family: "Bodoni Moda";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/bodoni-moda-italic.woff2") format("woff2");
  unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;

}

