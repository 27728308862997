.MobileNav {
  $primary-list-gutter: 30px;
  $gutter: 33px;
  $icon-size: 24px;
  $toggle-size: 59px;
  $toggle-z-index: 2;
  $menu-z-index: 1;

  --menu-circle-color: #fff;
  --menu-circle-size: #{fs-rem(22px)};
  --menu-size: #{fs-rem(82px)};
  --link-weight: #{$work-sans-semibold};

  display: flex;
  position: fixed;
  z-index: fs-zindex('mobileNav');
  width: 100%;

  @include fs-min-width($layout-nav-bp) {
    display: none;
  }

  &-toggle {
    @include button-reset;
    background-color: $bg-green-blue;
    height: fs-rem($toggle-size);
    margin-left: auto;
    margin-right: fs-rem(15px);
    margin-top: fs-rem(20px);
    padding: fs-rem(11px);
    position: relative;
    width: fs-rem($toggle-size);
    z-index: $toggle-z-index;

    &-burger {
      @include fs-burger(
        $color: $c-orange,
        $gutter: 8px,
        $width: 38px,
        $height: 2px
      );
      display: block;
    }

    &[aria-expanded="true"] &-burger {
      @include fs-burger-to-cross();
    }
  }

  &-menu {
    @include expandable-height;
    // @include fs-absolute-fill;
    background-color: $bg-green-blue;
    position: absolute;
    top: 0;
    z-index: $menu-z-index;
    width: 100%;
  }

  &-wrapper {
    padding: fs-rem(84px $gutter $gutter $gutter);
  }

  &-header {
    color: #fff;
    font-family: $work-sans;
    font-size: fs-rem(14px);
    font-weight: $work-sans-semibold;
    line-height: 1.14;
    margin-bottom: fs-rem(10px);
    text-transform: uppercase;
  }

  &-primaryList {
    background-color: rgba(#000, .7);
    margin-left: fs-rem(-$gutter);
    margin-right: fs-rem(-$gutter);
    padding-bottom: fs-rem($primary-list-gutter);
    padding-top: fs-rem($primary-list-gutter);

    &-item {
      padding: fs-rem(20px $gutter);
    }

    &-link {
      align-items: center;
      color: #fff;
      display: flex;
      font-family: $work-sans;
      font-size: fs-rem(18px);
      font-weight: var(--link-weight);
      overflow: hidden; // hides text
      text-transform: uppercase;

      &::before {
        background-color: var(--menu-circle-color);
        border-radius: 100%;
        content: '';
        display: inline-block;
        height: var(--menu-circle-size);
        width: var(--menu-circle-size);
        flex-shrink: 0;
        margin-right: fs-rem(18px);
      }

      @include fs-attention {
        --link-weight: bold;
        --menu-circle-color: #{$c-orange};
      }

      &.is-active {
        --link-weight: bold;
        --menu-circle-color: #{$c-orange};
      }
    }
  }

  &-secondaryList {
    margin-top: fs-rem(34px);
    text-align: center;

    &-item {
      & + & {
        border-top: 0.5px solid #fff;
      }
    }

    &-link {
      color: #fff;
      display: inline-block;
      font-size: fs-rem(14px);
      letter-spacing: 2px;
      padding-bottom: fs-rem(30px);
      padding-top: fs-rem(30px);
      text-transform: uppercase;

      svg {
        --icon-color: #{$c-orange};
        height: fs-rem($icon-size);
        margin-left: fs-rem(8px);
        vertical-align: middle;
        width: fs-rem($icon-size);
      }
    }

    &-downloadLink {
      border-bottom: 2px solid $c-orange;
      font-weight: bold;
      padding-bottom: fs-rem(2px);
    }
  }
}
