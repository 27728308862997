.PullQuote {
  $bp-small: 900px;
  border-top: solid $bg-blue 3px;
  color: $c-green-blue;
  margin-top: 30px;
  padding-top: 20px;

  @include fs-min-width($bp-small) {
    margin-left: 95px;
    margin-top: 118px;
  }

  &-quote {
    font-family: $bodoni-mioda;
    font-size: 30px;
    font-weight: bold;
    line-height: 32px;
    margin-left: 40px;
    position: relative;
  }

  &-quote::before {
    color: $bg-blue;
    content: '\201C';
    font-size: 60px;
    height: 22px;
    left: 0;
    position: absolute;
    top: 0;
    transform: translateX(-40px);
    width: 33px;
  }

  &-quote::after {
    content: '\201D';
  }

  &-author {
    font-size: 14px;
    font-weight: $work-sans-bold;
    letter-spacing: 1px;
    line-height: 16px;
    margin-left: 40px;
    margin-top: 30px;
    text-transform: uppercase;
  }
}
