.BackToTop {
  display: inline-block;
  margin-bottom: fs-rem(45px);
  text-align: center;

  &-text {
    font-size: fs-rem(14px);
    text-transform: uppercase;
    color: $c-green-blue;
    margin-bottom: fs-rem(30px);
    font-weight: bold;
  }

  &-link {
    display: inline-block;
    transition: 500ms transform, 500ms scale;

    .icon {
      --icon-color: #{$c-green-blue};
      --icon-border-color: #{$c-green-blue};
      --icon-bg-color: transparent;
      transform: rotateX(180deg);
    }

    @include fs-attention {
      scale: 1.1;
      .icon {
        --icon-color: #fff;
        --icon-border-color: #{$c-green-blue};
        --icon-bg-color: #{$bg-green-blue};
      }
    }
  }
}
