.NextPrev {
  $button-size: 40px;
  $next-btn-mobile-gutter: 45px;

  &-btn {
    --icon-size: #{$button-size};
    @include button-reset;
    height: var(--icon-size);
    left: 50%;
    position: absolute;
    transform: translateX(-50%);
    transition: 500ms transform, 500ms scale, 500ms fill;
    width: var(--icon-size);
    z-index: fs-zindex('next-prev');

    @include fs-attention {
      --icon-color: #{$c-red};
      scale: 1.1;
    }

    &--prev {
      --icon-color: #{$c-green-blue};
      --icon-border-color: #{$c-green-blue};
      --icon-bg-color: transparent;

      top: 0;
      transform: translateX(-50%) rotateX(180deg);

      @include fs-attention {
        --icon-color: #fff;
        --icon-bg-color: #{$bg-green-blue};
      }
    }

    &--next {
      --icon-color: #{$c-orange};
      --icon-border-color: #{$c-orange};
      --icon-bg-color: transparent;

      @include fs-max-width($layout-banner-bp - 1px) {
        bottom: 30px;
      }

      @include fs-min-width($layout-banner-bp) {
        bottom: $next-btn-mobile-gutter;
      }

      @include fs-attention {
        --icon-color: #fff;
        --icon-bg-color: #{$c-orange};
      }
    }

    &.is-hidden {
      display: none;
    }
  }

  // &-text {
  //   bottom: calc(#{$next-btn-mobile-gutter} + #{$button-size} + 13px);
  //   color: #fff;
  //   font-weight: $work-sans-bold;
  //   left: 50%;
  //   letter-spacing: 2px;
  //   position: absolute;
  //   text-align: center;
  //   text-transform: uppercase;
  //   transform: translateX(-50%);
  //   z-index: 2;

  //   @include fs-max-width($layout-banner-bp - 1px) {
  //     width: 100%;
  //   }
  // }
}
