.Downloads {
  $bp-small: 900px;

  @include fs-max-width($bp-small - 1px) {
    padding-top: fs-rem(50px);
  }

  @include fs-min-width($bp-small) {
    padding-bottom: fs-rem(140px);
    padding-top: fs-rem(140px);
  }

  &-slideTitle {
    padding-bottom: 10px;
    border-bottom: $bg-blue 3px solid;
    margin-bottom: 24px;
    display: inline-block;
  }

  &-description {
    margin-bottom: 16px;
  }

  &-resource {
    &-list {
      @include fs-min-width($bp-small) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 46px;
      }
    }

    &-item {
      list-style: none;

      @include fs-max-width($bp-small - 1px) {
        & + & {
          margin-top: fs-rem(40px);
        }
      }

      &-title {
        margin-bottom: 15px;
      }

      &-description {
        margin-bottom: 15px;
      }

      &-link {
        font-weight: $work-sans-bold;
        letter-spacing: 2px;
        text-transform: uppercase;
        color: #003240;

        .icon {
          --icon-size: 16px;
        }
      }
    }
  }
}
