.Tabs {
  $bp-mobile: 550px;
  margin-top: fs-rem(11px);

  &-title {
    color: $c-green-blue;
    font-size: fs-rem(25px);
    font-weight: $work-sans-bold;
    letter-spacing: 2px;
    line-height: 1.5;
    margin-bottom: fs-rem(8px);
    text-transform: uppercase;
  }

  &-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: fs-rem(30px);

    @include fs-max-width($bp-mobile - 1px) {
      flex-direction: column;
    }
  }

  &-item {
    list-style-type: none;

    & + & {
      @include fs-max-width($bp-mobile - 1px) {
        margin-top: fs-rem(9px);
      }

      @include fs-min-width($bp-mobile) {
        margin-left: fs-rem(8px);
      }
    }
  }

  &-link {
    @include button-border;
    background-color: var(--button-color);
    color: var(--button-hover-color);

    @include fs-max-width($bp-mobile - 1px) {
      font-size: fs-rem(14px);
    }

    &[aria-selected="true"] {
      --button-hover-color: #fff;
      --button-color: #{$bg-green-blue};
    }
  }

  &-section {}
}
