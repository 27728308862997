//------------------------------------------------------------------------
// Layout helper classes
//------------------------------------------------------------------------
// Note: Just checking for [class*="l-wrap"] can result in false positives
//       e.g. “Modal-wrap” would match
[class^="l-wrap"],
[class*=" l-wrap"] {
  margin-left: auto;
  margin-right: auto;
}

.l {

  // Default site padding
  &-padding {
    @include default-padding;

    // Optional class for applying vertical padding
    // to blocks with solid color backgrounds.
    &-vert {
      @include strata-vert-padding;
    }

    // end vert
  }

  &-wrap {
    max-width: fs-rem($layout-max-width);

    &--narrow {
      max-width: fs-rem($layout-max-width-narrow);
    }
  }

  // end wrap

  // Allow element to span viewport width regardless of parent width
  // NOTE: These styles only work when the parent wrapper is centered.
  &-fullwidth {
    @include layout-fullwidth;
  }

  // Default margin between blocks
  &-block {
    clear: both;

    &:not(.l-fullwidth) {
      @include block-vert-margin($props: margin-bottom margin-top);
    }

    // Add padding for Gutenberg blocks with “full” alignment
    &.alignfull {
      @include default-padding;
    }

    // Use this class to wrap content containing Gutenberg blocks
    // Note: This could also be move to “_richtext-mixin.scss” if desired.
    &-wrap {
      &>.l-block {
        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    // end wrap
  }

  // end block

  &-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100vw;
  }


  &-twoCol {
    $bp-mobile: 900px;

    align-items: center;
    display: flex;

    @include fs-max-width($bp-mobile - 1px) {
      margin-bottom: 16px;
    }

    @include fs-min-width($bp-mobile) {
      margin-bottom: 30px;
    }

    > *:first-child {
      flex-basis: 82%;
      margin-right: fs-rem(30px);
    }

    > *:last-child {
      flex-shrink: 0;
    }
  }
}

// end l
