.DoughnutCharts {
  $bp-3-col: 750px;
  $border-radius: 2px;

  &-wrapper {
    @include fs-min-width($bp-3-col) {
      display: flex;
      justify-content: space-between;
    }
  }

  &-list {
    @include fs-min-width($bp-3-col) {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
  }

  &-item {
    display: flex;
    flex-direction: column;

    @include fs-max-width($bp-3-col - 1px) {
      & + & {
        margin-top: fs-rem(45px);
      }
    }

    @include fs-min-width($bp-3-col) {
      flex-basis: 23%;

      & + & {
        margin-left: fs-rem(90px);
      }
    }
  }

  &-chart {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-between;

    &-legend {
      margin-bottom: fs-rem(14px);

      @include fs-max-width($bp-3-col - 1px) {
        margin-top: fs-rem(15px);
      }

      &-item {
        display: flex;

        & + & {
          margin-top: fs-rem(8px);
        }
      }

      &-square {
        background-color: var(--legend-color);
        border-radius: $border-radius;
        flex-shrink: 0;
        height: var(--square-size, #{fs-rem(14px)});
        margin-right: fs-rem(9px);
        width: var(--square-size, #{fs-rem(14px)});
      }

      &-label {
        font-size: fs-rem(12px);
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 1.33;
        text-transform: uppercase;
      }
    }

    &-title {
      font-size: fs-rem(12px);
      font-weight: $work-sans-bold;
      letter-spacing: 1px;
      line-height: 1.33;
      margin-top: fs-rem(10px);
      order: 3;
      text-align: center;
      text-transform: uppercase;
    }
  }

  &-download {
    margin-top: fs-rem(50px);

    &-link {
      @include link-download;
      display: inline-flex;
      align-items: center;
      text-decoration: underline;
      text-decoration-color: $c-blue;

      &-text {
        border-bottom: 2px solid $c-blue;
      }
    }
  }
}
