.CopyLink {
  --icon-bg-color: #fff;
  --icon-color: #{$c-blue};
  --icon-size: #{fs-rem(32px)};

  @include fs-reset-button;
  height: var(--icon-size);
  position: relative;
  transition: fill 500ms, transform 500ms;
  width: var(--icon-size);

  @include button-attention {
    --icon-bg-color: #{$c-blue};
    --icon-color: #fff;
    --icon-scale: 1.15;
    // transform: scale(1.15);
  }

  .icon {
    height: 100%;
    width: 100%;
  }

  &-tooltip {
    background-color: $c-green-blue;
    border-radius: 5px;
    box-shadow: 0 0 0 #000;
    color: #fff;
    font-size: fs-rem(14px);
    font-weight: bold;
    letter-spacing: 1px;
    padding: 8px;
    position: absolute;
    text-transform: uppercase;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    transition: opacity 500ms;
    opacity: 0;

    &.show {
      opacity: 1;
    }

    &:before {
      content: '';
      display: block;
      width: 0;
      height: 0;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%, 100%);
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;

      border-top: 5px solid $c-green-blue;
    }
  }
}
